@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*:not(input):not(textarea) {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  font-family: "Poppins", sans-serif;
  background: rgb(0, 0, 0);
  padding: 20px;
}
.intro > h1 {
  color: white;
  font-size: 3rem;
  text-align: left;
  margin-top: 80px;
  z-index: 999;
  font-weight: 300;
  line-height: 55px;
}

.intro > p {
  color: rgb(132, 132, 132);
  font-size: 0.7rem;
  text-align: left;
  margin-top: 20px;
  z-index: 999;
  padding-right: 40px;
  font-weight: 400;
}

.carImg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 500px;
  height: 500px;
  background-image: url("https://images.hdqwalls.com/download/bugatti-la-voiture-noire-2019-side-view-66-800x1280.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  background-position: 100px -180px;
  z-index: -1;
}
@keyframes car-animation {
  from {
    background-position: 100px -180px;
  }
  to {
    background-position: 200px -180px;
  }
}

.buttonIntro {
  width: 250px;
  height: 60px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: 40px;
  background-color: rgba(0, 0, 0, 0);
  border: 0.5px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  z-index: 999;
  transition: all 0.5s;
}

.bgSpeedometer {
  height: 120px;
  top: 290px;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  display: none;
  transition: all 2s;
  padding: 10px;
}
.speedometerLine {
  height: 1px;
  background-color: white;
  width: 0%;
}

.percent {
  color: white;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-size: 33px;
}
.speedometerNumber {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-size: 73px;
}

@keyframes speedometer-line-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* App.css */

.fade-in {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  width: 100%;
  height: 70px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5); /* Arka planı hafif şeffaf yapın */
  top: 0px;
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  backdrop-filter: blur(35px); /* Bulanıklık efekti ekle */
}

.logo {
  width: 100px;
}
.title {
  color: white;
  font-weight: 200;
  font-style: italic;
  text-align: right;
}
.carDiv {
  border-radius: 20px;
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 20px;
  background: linear-gradient(
    35deg,
    rgb(0, 0, 0) 0%,

    rgb(0, 0, 0) 35%,
    rgb(10, 10, 10) 35%,

    rgb(10, 10, 10) 55%,
    rgb(0, 0, 0) 55%,

    rgb(0, 0, 0) 100%
  );
  flex-direction: column;
  color: white;
}
.Filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -30px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 20px;
}
.Filter > p {
  color: white;
  line-height: 4px;
}
.welcomePage {
  color: white;
  font-weight: 200;
}
.welcomePage > h1 {
  font-weight: 200;
  font-size: 1.3rem;
  line-height: 0cm;
}
.welcomePage > h1 > span {
  font-weight: 600;
}
.carDetail {
  margin-top: 5px;
  display: flex;
  gap: 17px;
  font-size: 12px;
  z-index: 1;
}
.carDetailMonitor {
  margin-top: 5px;
  display: flex;
  gap: 17px;
  font-size: 12px;
  z-index: 1;
  flex-direction: column;
  padding: 20px;
}
.carPrice {
  font-size: 20px;
  line-height: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.carPrice > h3 > span {
  font-size: 20px;
  font-weight: 200;
}

.carPrice > p {
  font-size: 14px;
  font-weight: 200;

  font-style: italic;
}

.carBrand {
  line-height: 4px;
}
.carPhoto {
  position: absolute;
  right: -70px;
  z-index: 0;
}
.carCardPhoto {
  width: 220px;
}
.buttonCarDiv {
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 4px #faff00;
  border-radius: 50%;
  border: 0px solid black;
  padding: 16px;
  background-color: #faff00;
  position: absolute;
  bottom: 2px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Sağ alt köşedeki "delik" için psödo-eleman */
.carDiv::before {
  content: "";
  width: 90px; /* "delik" boyutu */
  height: 90px; /* "delik" boyutu */
  background-color: black; /* deliğin rengi */
  border-radius: 40%; /* daire şeklini alması için */
  position: absolute;
  right: -25px; /* dairenin konumunu ayarlayın */
  bottom: -25px; /* dairenin konumunu ayarlayın */
}

.signUp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

/* .signUpDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width:98%;
  height: 80vh;
  background-color: rgb(0, 0, 0);
  border-radius: 20px;
  color: white;
} */

.signUpForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.signUpForm > input {
  width: 90%;
  height: 50px;
  border-radius: 0px;
  border: 0px !important;
  border-bottom: 2px solid white !important;
  background-color: rgb(0, 0, 0);
  border: 1px solid white;
  color: white;
  padding-left: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.signUpButton {
  margin-top: 20px;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  background-color: rgb(0, 0, 0);
  border: 1px solid white;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
}

.carDetailPage {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
  width: 100%;
  height: 100%;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 20px;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgb(19, 19, 19));
}

.shake-2s-pause-3s {
  animation: shakeAnimation 1s ease 2;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}
.signUp {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #00000000;
}

.signUpDiv {
  background: #000000;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.signUpDiv > h1 {
  text-align: center;
  font-size: 1.6rem;
  color: #fff;
  font-family: "poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.toggleButton {
  background: none;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 14px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

.toggleButton.active {
  background-color: #faff00;
  color: #000000;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputField {
  background: #00000000;
  border: 1px solid #525252;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 30px;
  font-family: "poppins", sans-serif;
  padding: 10px;
  color: #fff;
}

.submitBtn {
  background-color: #faff00;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #000000;
  transition: background-color 0.3s ease;
  font-family: "poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
}
hr {
  width: 100%;
  height: 1px;
  background-color: #ababab;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
}
.submitBtnGoogle {
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 20px;
  padding: 10px;
  color: #000000;
  transition: background-color 0.3s ease;
  font-family: "poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.submitBtn:hover {
  background-color: #faff00;
  color: #000000;
}

.forgotPassword {
  display: block;
  color: #bbb;
  text-align: center;
  margin-top: 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgotPassword:hover {
  color: #fff;
}
.logoutButton {
  background-color: rgb(130, 0, 0);
  border: none;
  border-radius: 10px;
  padding: 10px;
  color: #ffffff;
  transition: background-color 0.3s ease;
  font-family: "poppins", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  display: flex;
  height: 47px;
  width: 135px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.editButton {
  background-color: #1e1e1e;
  border: none;
  border-radius: 10px;
  padding: 10px;
  color: #ffffff;
  transition: background-color 0.3s ease;
  font-family: "poppins", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  display: flex;
  height: 47px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99999;
}

.tab-bar {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

.tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #333;
  border: none;
  background: none;
  cursor: pointer;
}

.icon {
  font-size: 20px;
}

.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 20px;
  background-color: #660000;
  border: none;
  border-radius: 50%;
  height: 10vw;
  width: 10vw;
  max-width: 40px;
  max-height: 40px;
  padding: 10px;
  color: #ffffff;
  font-family: "poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.4px solid white;
  z-index: 999;
}
.floatingButton:hover {
  background-color: #faff00;
  border: 1.4px solid white;
  color: rgb(0, 0, 0);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeout {
  animation-name: fadeOut;
  animation-duration: 1s; /* Animasyonun süresini ayarlayabilirsiniz */
  animation-fill-mode: forwards; /* Animasyonun son durumunu korur */
}
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
}

.popup-content {
  animation: fadeIn 0.5s;
}



.FadeIn {
  animation: fadeIn 1s ease;
}

.popup-content {
  position: relative;
  margin: auto;
  width: 79%;
  pointer-events: auto;
  transition: all 0.5s;
}

.feature {
  font-size: 14px;
  text-align: left;
}
.feature > span {
  margin-left: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
  color: white; /* İkon ve metin rengi */
}

.fa-duotone {
  --fa-primary-color: rgb(177, 177, 177);
  --fa-secondary-color: rgb(88, 88, 88);
  --fa-secondary-opacity: 1; /* İkincil rengin opaklığı */
}

.fa-verified {
  --fa-primary-color: rgb(0, 0, 0);
  --fa-secondary-color: rgb(0, 137, 7);
  --fa-secondary-opacity: 1; /* İkincil rengin opaklığı */
}
.fa-unverified {
  --fa-primary-color: rgb(0, 0, 0);
  --fa-secondary-color: rgb(137, 0, 0);
  --fa-secondary-opacity: 1; /* İkincil rengin opaklığı */
}

.cardContainer {
  perspective: 1000px; /* Çevirme efekti için perspektif değeri */
}
.payNow{
  position: absolute;
  top: -23px;
  left: 0px;
}
.card {
  width: 300px; /* Kartın genişliği */
  height: 400px; /* Kartın yüksekliği */
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s; /* Çevirme animasyonu */
}

.card.is-flipped {
  transform: rotateY(180deg); /* Kart çevrildiğinde 180 derece dönüş */
}

.cardFace {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Kartın arka yüzü gizleniyor */
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardBack {
  transform: rotateY(180deg);
}

.reserveinput {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 50px;
}

@keyframes borderAnimation {
  0% {
    border-color: rgb(33, 33, 33);
  }
  50% {
    border-color: rgb(52, 52, 52);
  }
  100% {
    border-color: rgb(33, 33, 33);
  }
}

.dropinput {
  background-color: transparent;
  border: 2px solid rgb(255, 255, 255);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
  width: 100% !important;
  min-width: 140px;
  height: 50px;
  animation: borderAnimation 4s infinite;
  text-align: left;
  margin-top: 5px;
}

select,
option {
  color: white;
  background-color: rgb(0, 0, 0);
  font-family: "poppins", sans-serif;
  font-weight: 700;
  font-size: 13px;
}



@keyframes slideOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate__slideOut {
  animation: slideOut 0.5s ease-in-out forwards;
}

.animate__slideIn {
  animation: slideIn 0.5s ease-in-out forwards;
}

.history {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  gap: 10px;
  margin-top: 60px;
}

.historyDiv {
  position: relative;
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: space-around;
  align-content: space-around;
  gap: 10px;
  background: #252525;
  width: 100%;
  border-radius: 30px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.now {
  background-color: #000414;
  border: solid 3px rgb(0, 78, 155);
}

.old {
  background: linear-gradient(
    70deg,
    rgb(82, 0, 0) 40%,
    rgb(0, 0, 0) 40%,
    rgb(0, 0, 0) 60%,
    rgb(82, 0, 0) 60%,
    rgb(82, 0, 0) 100%
  );
}

.now::before {
  content: "";
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 25px;
  background-color: #000000;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border: solid 3px rgb(0, 78, 155);
  border-top: 0px;
}

.historyHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  text-align: center;
}

.ticketTitle {
  font-size: 12px;
  margin: 0px;
}

.ticketP {
  font-size: 12px;
  margin: 0px;
}


.brand-button:focus {
  outline: none;
  box-shadow: none;
  animation: brand-button-Animated 0.5s ease-in-out forwards;
}

@keyframes brand-button-Animated {
    0% {
      background-color: #000000;
    }
    50% {
        background-color: #1e1e1e;
    }
    100% {
        background-color: #000000;
    }
  }


  .filters {
    display: flex;
    overflow-x: auto;
    background-color: black;
    padding: 10px;
  }
  
  .filters button {
    align-items: center;
    padding: 10px;
    background-color: #000000;
    border: 1px solid rgb(250, 255, 0);
    border-radius: 5px;
    color: #fff;
    display: flex;
    text-align: left;
    flex: 0 0 auto;
    flex-direction: row;
    font-size: 0.8rem;
    gap: 10px;
    height: 53px;
    justify-content: center;
    margin-right: 10px;
    box-sizing: border-box;
    transition: background-color .3s ease;
    width: 108px;
  }
  
  .filters button:hover {
    background-color: rgb(250, 255, 0);
    color:black;
  }
  
  .feature{
    font-size: 1.1rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }